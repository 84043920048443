// BottomMenu.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserPlus, faToggleOn, faTruck, faIdCard, faUsers, faSignOutAlt, faBriefcase, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import AddBusinessPartnerForm from './AddBusinessPartnerForm';
import BusinessPartnerForm from './BusinessPartnerForm';
import useGetBPs from './hooks/useGetBPs'; // Import useGetBPs hook
import './BottomMenu.css';

const BottomMenu = ({ setSelectedMenuItem, logout, isVisible, selectedMenuItem, onAddUser, onToggleUser, userProfile ,businessPartners,setBusinessPartners }) => {
  const [showAddBPForm, setShowAddBPForm] = useState(false);
  const { bps, setBps, setShouldFetch } = useGetBPs(selectedMenuItem, userProfile); // Use useGetBPs hook

  const handleAddSuccess = (data) => {
    //console.log('Business Partner added successfully:', data);
    //console.log('Business Partners:', businessPartners);
    setShowAddBPForm(false);
    setShouldFetch(true); // Set shouldFetch to true to refetch data
    // Update the businessPartners list with the new data where the keys match
    const normalizedData = {
      BusinessPartner: data.businessPartnerName,
      ...data
      // si quiero mapear mas campos para el bp, aqui es el lugar para hacerlo
    };

    // Add the new entry to the businessPartners list
    const updatedBusinessPartners = [...businessPartners, normalizedData];
    console.log('Updated Business Partners:', updatedBusinessPartners);
    setBusinessPartners(updatedBusinessPartners);
  };

  const handleAddError = (error) => {
    console.error('Error adding Business Partner:', error);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('overlay')) {
      setShowAddBPForm(false);
    }
  };

  const handleToggleBP = (bp) => {
    // Logic to toggle the CopilotEnabled attribute
    const updatedBps = bps.map((b) =>
      b.BusinessPartner === bp.BusinessPartner
        ? { ...b, CopilotEnabled: !b.CopilotEnabled }
        : b
    );
    // Update the state with the new bps array
    setBps(updatedBps);
  };

  return (
    <>
      {showAddBPForm && (
        <div className="overlay" onClick={handleOverlayClick}>
          <AddBusinessPartnerForm
            userProfile={userProfile}
            onAddSuccess={handleAddSuccess}
            onAddError={handleAddError}
          />
        </div>
      )}
      <div className="content-container">
        {selectedMenuItem === 'Enable BPs' && (
          <div className="bp-container">
            <BusinessPartnerForm bps={bps} onToggleBP={handleToggleBP} />
          </div>
        )}
      </div>
      <div className={`bottom-menu ${isVisible ? 'visible' : 'hidden'}`}>
        {selectedMenuItem === 'Users' ? (
          <>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('')}>
                <FontAwesomeIcon icon={faArrowLeft} />
                <div>Back</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={onAddUser}>
                <FontAwesomeIcon icon={faUserPlus} />
                <div>Add User</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={onToggleUser}>
                <FontAwesomeIcon icon={faToggleOn} />
                <div>Enable/Disable</div>
              </button>
            </div>
          </>
        ) : selectedMenuItem === 'Enable BPs' ? (
          <>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('MyBP')}>
                <FontAwesomeIcon icon={faArrowLeft} />
                <div>Back</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={() => setShowAddBPForm(true)}>
                <FontAwesomeIcon icon={faUserPlus} />
                <div>Add Business Partner</div>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('Enable BPs')}>
                <FontAwesomeIcon icon={faBriefcase} />
                <div>My BP's</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('Users')}>
                <FontAwesomeIcon icon={faUsers} />
                <div>Users</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('Carriers Admin')}>
                <FontAwesomeIcon icon={faTruck} />
                <div>Carriers</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('Drivers Admin')}>
                <FontAwesomeIcon icon={faIdCard} />
                <div>Drivers</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={() => setSelectedMenuItem('Help')}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <div>Help</div>
              </button>
            </div>
            <div className="bottom-menu-item">
              <button onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <div>Logout</div>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BottomMenu;