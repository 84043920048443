// src/hooks/useUsers.js
import { useState, useEffect } from 'react';

const useUsers = (selectedMenuItem, userProfile) => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      if (selectedMenuItem === 'Users' && userProfile) {
        const { name, user_metadata } = userProfile;
        const businessPartner = user_metadata.BusinessPartner;
        const parent = user_metadata.Parent;
        const level = user_metadata.Level;

        const queryParams = new URLSearchParams({
          name,
          businessPartner,
          parent,
          level
        }).toString();

        try {
          const response = await fetch(`https://fleetbooster.net/getCopilotAdminUsers?${queryParams}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setUsers(data);
        } catch (error) {
          setError('Error fetching users: ' + error.message);
        }
      }
    };

    fetchUsers();
  }, [selectedMenuItem, userProfile]);

  return { users, setUsers, error };
};

export default useUsers;