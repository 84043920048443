// src/hooks/useCarriers.js
import { useState, useEffect } from 'react';

const useCarriers = (selectedMenuItem, userProfile) => {
  const [carriers, setCarriers] = useState([]);
  const [error, setError] = useState('');
  //console.log('userProfile: ', userProfile);
  useEffect(() => {
    const fetchCarriers = async () => {
      if (selectedMenuItem === 'Carriers Admin' && userProfile) {
        try {
          const response = await fetch(`https://fleetbooster.net/getCarriersCopilot?BusinessPartner=${userProfile.user_metadata.BusinessPartner}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setCarriers(data);
          //console.log('data: ', data);
        } catch (error) {
          setError('Error fetching carriers: ' + error.message);
        }
      }
    };

    fetchCarriers();
  }, [selectedMenuItem, userProfile]);

  return { carriers, setCarriers, error };
};

export default useCarriers;