// hooks/useAddUser.js
import { toast } from 'react-toastify';
//import { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';

const useAddUser = () => {
  const addUser = async (user, userProfile) => {
    try {
      const response = await fetch('https://fleetbooster.net/addCopilotAdminUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          name: user.name,
          lastName: user.lastName,
          email: user.email,
          businessPartner: user.businessPartner,
          password: user.password,
          parent: userProfile.user_metadata.BusinessPartner,
          level: userProfile.user_metadata.Level + 1,
          createdBy: userProfile.name,
          grandParent: userProfile.user_metadata.Parent,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      toast.success('User added successfully', { autoClose: 1000 });
      return responseData;
    } catch (error) {
      toast.error('Error adding user: ' + error.message, { autoClose: 5000 });
    }
  };

  return { addUser };
};

export default useAddUser;