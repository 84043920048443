// MainContentWrapper.js
import React from 'react';
import MainContent from './MainContent';
import AddUserForm from './AddUserForm';
import UserCard from './UserCard';
import HelpCard from './HelpCard';

const MainContentWrapper = ({
  selectedMenuItem,
  apiKeys,
  selectedApiKey,
  setSelectedApiKey,
  searchQuery,
  handleSearch,
  filteredDrivers,
  handleAddDriver,
  handleEnableDriver,
  handleDisableDriver,
  handleToggleUser,
  successMessages,
  error,
  carrierApiKey,
  setCarrierApiKey,
  handleValidateCarrier,
  carriers,
  handleEnableCarrier,
  handleDisableCarrier,
  users,
  showAddUserForm,
  handleAddUser,
  userProfile,
  businessPartners = [],
  defaultBusinessPartner
  
}) => {
  return (
    <div className="main-content-wrapper">
      {selectedMenuItem === 'Help' ? (
        <HelpCard />
      ) : selectedMenuItem === 'Users' ? (
        showAddUserForm ? (
          <AddUserForm onAddUser={handleAddUser} userProfile={userProfile} businessPartners={businessPartners} defaultBusinessPartner={defaultBusinessPartner} />
        ) : (
          <div className="user-cards-container">
            {users.map((user) => (
              <UserCard
                key={user.name + user.lastName}
                user={user}
                onToggle={handleToggleUser}
              />
            ))}
          </div>
        )
      ) : (
        <MainContent
          selectedMenuItem={selectedMenuItem}
          apiKeys={apiKeys}
          selectedApiKey={selectedApiKey}
          setSelectedApiKey={setSelectedApiKey}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          filteredDrivers={filteredDrivers}
          handleAddDriver={handleAddDriver}
          handleEnableDriver={handleEnableDriver}
          handleDisableDriver={handleDisableDriver}
          handleToggleUser={handleToggleUser}
          successMessages={successMessages}
          error={error}
          carrierApiKey={carrierApiKey}
          setCarrierApiKey={setCarrierApiKey}
          handleValidateCarrier={handleValidateCarrier}
          carriers={carriers}
          handleEnableCarrier={handleEnableCarrier}
          handleDisableCarrier={handleDisableCarrier}
          userProfile={userProfile}
          users={users}
          defaultBusinessPartner={defaultBusinessPartner}
          
        />
      )}
    </div>
  );
};

export default MainContentWrapper;