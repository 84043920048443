// src/hooks/useGetBPs.js
import { useState, useEffect } from 'react';

const useGetBps = (selectedMenuItem, userProfile) => {
  const [bps, setBps] = useState([]);
  const [error, setError] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true); // State variable to control fetching

  useEffect(() => {
    const fetchBusinessPartners = async () => {
      if (selectedMenuItem === 'Enable BPs' && userProfile && shouldFetch) {
        try {
          const response = await fetch(`https://fleetbooster.net/getCopilotBusinessPartner?BusinessPartner=${userProfile.user_metadata.BusinessPartner}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setBps(data);
          setShouldFetch(false); // Set shouldFetch to false after fetching data
        } catch (error) {
          setError('Error fetching business partners: ' + error.message);
        }
      }
    };

    fetchBusinessPartners();
  }, [selectedMenuItem, userProfile, shouldFetch]);

  return { bps, setBps, error, setShouldFetch };
};

export default useGetBps;