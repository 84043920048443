// Header.js
import React from 'react';
import './header.css';

const Header = ({ userProfile }) => {
  return (
    <header className="header">
      <h1>Copilot Admin's Toolbox</h1>
      {userProfile && <p className="user-profile-name">Welcome, {userProfile.name}</p>}
    </header>
  );
};

export default Header;