// UserCard.js
import React from 'react';
import './UserCard.css';

const UserCard = ({ user, onToggle }) => {
  const isEnabled = Number(user.user_metadata.CopilotEnabled) === 1;

  const handleToggle = () => {
    console.log('UserCard - handleToggle:', user);
    onToggle(user);
  };

  return (
    <div className={`user-card ${isEnabled ? 'enabled' : 'disabled'}`}>
      <h3>{user.name}</h3>
      <p>Business Partner: {user.user_metadata.BusinessPartner}</p>
      <p>Parent: {user.user_metadata.Parent}</p>
      <p>Level: {user.user_metadata.Level}</p>
      <label className="switch">
        <input type="checkbox" checked={isEnabled} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default UserCard;