import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './DriverCard.css'; // Import the CSS file for styling

const DriverCard = ({ driver, onAdd, onEnable, onDisable, successMessage, errorMessage }) => {
  const [password, setPassword] = useState(driver.password || '');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setPassword(driver.password || '');
  }, [driver]);

  const getBadgeIcon = (status) => {
    const numericStatus = Number(status); // Cast status to a number
    //console.log('Badge Icon - Status:', numericStatus, 'Type:', typeof numericStatus); // Log the status and its type
    switch (numericStatus) {
      case -1:
        return faTimes;
      case 0:
        return faCheck;
      case 1:
        return faCheck;
      default:
        return faCheck;
    }
  };

  const getBadgeColor = (status) => {
    const numericStatus = Number(status); // Cast status to a number
    //console.log('Badge Color - Status:', numericStatus, 'Type:', typeof numericStatus); // Log the status and its type
    switch (numericStatus) {
      case -1:
        return 'red';
      case 0:
        return '#b8860b'; // Dark Yellow
      case 1:
        return 'green';
      default:
        return 'gray';
    }
  };

  return (
    <div className="driver-card">
      <span className="status-badge" style={{ borderColor: getBadgeColor(driver.CopilotEnabled) }} title="Copilot driver current status in the system">
        <FontAwesomeIcon icon={getBadgeIcon(driver.CopilotEnabled)} style={{ color: getBadgeColor(driver.CopilotEnabled) }} />
      </span>
      <div className="driver-info">
        <p><strong>Username:</strong> {driver.HOSUserName}</p>
        <p><strong>Last Name:</strong> {driver.LastName}</p>
        <p><strong>Name:</strong> {driver.Name}</p>
        <p><strong>License Number:</strong> {driver.LicenseNumber}</p>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
            title={showPassword ? "Hide password" : "Show password"}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>
      </div>
      <div className="driver-actions">
        <button className="add" onClick={() => onAdd({ ...driver, password })} title="to Add the driver to copilot">
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <button className="enable" onClick={() => onEnable(driver)} title="Enable the driver in copilot">
          <FontAwesomeIcon icon={faCheck} />
        </button>
        <button className="disable" onClick={() => onDisable(driver)} title="Disable the driver in copilot">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default DriverCard;