import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Header from './header';
import HelpCard from './HelpCard';
import MainContentWrapper from './MainContentWrapper';
import BottomMenu from './BottomMenu';
import useApiKeys from './hooks/useApiKeys';
import useDrivers from './hooks/useDrivers';
import useCarriers from './hooks/useCarriers';
import useUsers from './hooks/useUsers';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { loginWithRedirect, logout, isAuthenticated, isLoading, user } = useAuth0();
  const { apiKeys } = useApiKeys(user);
  const [selectedApiKey, setSelectedApiKey] = useState('');
  const { drivers, setDrivers, filteredDrivers, setFilteredDrivers } = useDrivers(selectedApiKey);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [successMessages, setSuccessMessages] = useState({});
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [carrierApiKey, setCarrierApiKey] = useState('');
  const [, setCarrierResult] = useState(null);
  const [, setCarrierStatus] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const [defaultBusinessPartner, setDefaultBusinessPartner] = useState('');
  const { users, setUsers } = useUsers(selectedMenuItem, userProfile);
  const { carriers, setCarriers } = useCarriers(selectedMenuItem, userProfile); // Ensure props are passed
  const [isBottomMenuVisible, setIsBottomMenuVisible] = useState(true);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [businessPartners, setBusinessPartners] = useState([]); // Define businessPartners and setBusinessPartners

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      setUserProfile(user);
      fetchBusinessPartners(user);
    } else if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ connection: 'Copilot' });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, user]);

  const fetchBusinessPartners = async (userProfile) => {
    try {
      const response = await fetch(`https://fleetbooster.net/getCopilotBusinessPartner?BusinessPartner=${userProfile.user_metadata.BusinessPartner}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const extraItem = {
        BusinessPartner: userProfile.user_metadata.BusinessPartner, // Assuming 'BusinessPartner' is the key for the dropdown value
        CreatedBy: userProfile.user_metadata.BusinessPartner // Assuming 'CreatedBy' is the key for the dropdown display text
      };
      const modifiedData = [...data, extraItem];
      setBusinessPartners(modifiedData);
      setDefaultBusinessPartner(extraItem.BusinessPartner); // Set the default business partner
      console.log('Business Partners:', modifiedData); // Debugging line to check the modified data
    } catch (error) {
      console.error('Error fetching business partners:', error);
    }
  };

  useEffect(() => {
    let timeout;
    const handleUserActivity = () => {
      setIsBottomMenuVisible(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsBottomMenuVisible(false);
      }, 3000); // Hide after 3 seconds of inactivity
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeout);
    };
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredDrivers(drivers);
    } else {
      const regex = new RegExp(query.replace('*', '.*'), 'i');
      setFilteredDrivers(drivers.filter(driver => regex.test(driver.HOSUserName)));
    }
  }, [drivers, setFilteredDrivers]);

  const handleAddDriver = useCallback(async (driver) => {
    if (!driver.HOSDriverId) {
      setError('No driver selected');
      return;
    }
    if (!selectedApiKey) {
      setError('No API key selected');
      return;
    }
    const licenseNumber = driver.password || driver.LicenseNumber;
    try {
      const response = await fetch('https://fleetbooster.net/addDriverCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: selectedApiKey,
          HOSDriverId: driver.HOSDriverId,
          LastName: driver.LastName,
          Name: driver.Name,
          LicenseNumber: licenseNumber,
          HOSUserName: driver.HOSUserName,
          HOSHomeBaseId: driver.HOSHomeBaseId
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      setDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, CopilotEnabled: responseData.CopilotEnabled } : d));
      setFilteredDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, CopilotEnabled: responseData.CopilotEnabled } : d));
      setSuccessMessages((s) => ({ ...s, [driver.HOSDriverId]: 'Driver added successfully' }));
    } catch (error) {
      setError('Error adding driver: ' + error.message);
    }
  }, [selectedApiKey, drivers, setDrivers, setFilteredDrivers]);

  const handleEnableDriver = useCallback(async (driver) => {
    if (!driver.HOSDriverId) {
      setError('No driver selected');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/enableDriverCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: selectedApiKey,
          LastName: driver.LastName,
          Name: driver.Name,
          HOSUserName: driver.HOSUserName
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, CopilotEnabled: 1 } : d));
      setFilteredDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, CopilotEnabled: 1 } : d));
      setSuccessMessages((s) => ({ ...s, [driver.HOSDriverId]: 'Driver enabled successfully' }));
    } catch (error) {
      setError('Error enabling driver: ' + error.message);
    }
  }, [selectedApiKey, drivers, setDrivers, setFilteredDrivers]);

  const handleDisableDriver = useCallback(async (driver) => {
    if (!driver.HOSDriverId) {
      setError('No driver selected');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/disableDriverCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: selectedApiKey,
          LastName: driver.LastName,
          Name: driver.Name,
          HOSUserName: driver.HOSUserName
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, CopilotEnabled: 0 } : d));
      setFilteredDrivers(drivers.map(d => d.HOSDriverId === driver.HOSDriverId ? { ...d, CopilotEnabled: 0 } : d));
      setSuccessMessages((s) => ({ ...s, [driver.HOSDriverId]: 'Driver disabled successfully' }));
    } catch (error) {
      setError('Error disabling driver: ' + error.message);
    }
  }, [selectedApiKey, drivers, setDrivers, setFilteredDrivers]);

  const handleToggleUser = useCallback(async (user) => {
    const newStatus = user.user_metadata.CopilotEnabled === 1 ? 0 : 1;

    try {
      const response = await fetch('https://fleetbooster.net/changeCopilotAdminUserStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          user_id: user.user_id,
          action: newStatus
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Update the CopilotEnabled status locally
      const updatedUsers = users.map(u => 
        u.user_id === user.user_id 
          ? { ...u, user_metadata: { ...u.user_metadata, CopilotEnabled: newStatus } }
          : u
      );
      setUsers(updatedUsers);
    } catch (error) {
      setError('Error changing user status: ' + error.message);
    }
  }, [users, setUsers]);


  const handleValidateCarrier = useCallback(async (carrierApiKey, userProfile) => {
    if (!carrierApiKey) {
      setError('No carrier API key provided');
      toast.error('No carrier API key provided');
      return;
    }
    //console.log('Validating carrier:', carrierApiKey, 'userProfile:', userProfile);
    try {
      const response = await fetch(`https://fleetbooster.net/validateCarrierCopilot?ApiKey=${carrierApiKey}&BusinessPartner=${userProfile.user_metadata.BusinessPartner}`);
      if (response.status === 405) {
        toast.error('Invalid Carrier API, it is not defined in the ELD System');
        return;
      }
      if (response.status === 406) {
        toast.error('API key is valid, but is already assigned');
        return;
      }
      if (!response.ok) {
        toast.error('Network response was not ok');
        return;
      }
      const data = await response.json();
      setCarrierResult(data);
      setCarrierStatus('Carrier validated successfully');
      toast.success('Carrier validated successfully');
    } catch (error) {
      setError('Error validating carrier: ' + error.message);
      toast.error('Error validating carrier: ' + error.message);
    }
  }, [setCarrierResult, setCarrierStatus, setError]);

  const handleEnableCarrier = useCallback(async (carrier) => {
    if (!carrier.ApiKey) {
      setError('No carrier API key provided');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/enableCarrierCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: carrier.ApiKey
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update the CopilotEnabled status locally
      const updatedCarriers = carriers.map(c => 
        c.ApiKey === carrier.ApiKey 
          ? { ...c, CopilotEnabled: 1 }
          : c
      );
      setCarriers(updatedCarriers);
      setCarrierStatus('Carrier enabled successfully');
    } catch (error) {
      setError('Error enabling carrier: ' + error.message);
    }
  }, [carriers, setCarriers]);

  const handleDisableCarrier = useCallback(async (carrier) => {
    if (!carrier.ApiKey) {
      setError('No carrier API key provided');
      return;
    }
    try {
      const response = await fetch('https://fleetbooster.net/disableCarrierCopilot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          ApiKey: carrier.ApiKey
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update the CopilotEnabled status locally
      const updatedCarriers = carriers.map(c => 
        c.ApiKey === carrier.ApiKey 
          ? { ...c, CopilotEnabled: 0 }
          : c
      );
      setCarriers(updatedCarriers);
      setCarrierStatus('Carrier disabled successfully');
    } catch (error) {
      setError('Error disabling carrier: ' + error.message);
    }
  }, [carriers, setCarriers]);

  const handleAddUser = (user) => {
    // Logic to add a user
    console.log('Add User', user);
  };

  const handleMyBusinessPartners = () => {
    // Logic to handle My Business Partners
    console.log('My Business Partners');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <HelpCard />;
   // return <div>Redirecting to login...</div>;
  }

  return (
    <div className="app-container">
      <Header userProfile={userProfile} />
      {selectedMenuItem === 'MyBP' && (
        <div className="business-partner-card">
          {/* Render business partner card here */}
        </div>
      )}
      <MainContentWrapper
        selectedMenuItem={selectedMenuItem}
        apiKeys={apiKeys}
        selectedApiKey={selectedApiKey}
        setSelectedApiKey={setSelectedApiKey}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        filteredDrivers={filteredDrivers}
        handleAddDriver={handleAddDriver}
        handleEnableDriver={handleEnableDriver}
        handleDisableDriver={handleDisableDriver}
        handleToggleUser={handleToggleUser}
        successMessages={successMessages}
        error={error}
        carrierApiKey={carrierApiKey}
        setCarrierApiKey={setCarrierApiKey}
        handleValidateCarrier={handleValidateCarrier}
        carriers={carriers}
        handleEnableCarrier={handleEnableCarrier}
        handleDisableCarrier={handleDisableCarrier}
        users={users}
        showAddUserForm={showAddUserForm}
        handleAddUser={handleAddUser}
        userProfile={userProfile}
        businessPartners={businessPartners}
        defaultBusinessPartner={defaultBusinessPartner}
      />
      <BottomMenu
        setSelectedMenuItem={setSelectedMenuItem}
        logout={logout}
        isVisible={isBottomMenuVisible}
        selectedMenuItem={selectedMenuItem}
        onAddUser={() => setShowAddUserForm(true)}
        onToggleUser={() => setShowAddUserForm(false)}
        onMyBusinessPartners={handleMyBusinessPartners}
        userProfile={userProfile}
        businessPartners= {businessPartners}
        setBusinessPartners={setBusinessPartners} 
      />
      <ToastContainer />
    </div>
  );
}

export default App;