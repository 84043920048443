import React from 'react';
import './CarrierCard.css'; // Import the CSS file for styling

const CarrierCard = ({ carrier, onEnable, onDisable }) => {
  
  const isEnabled = Number(carrier.CopilotEnabled) === 1;

  const handleToggle = () => {
    if (isEnabled) {
      onDisable(carrier);
    } else {
      onEnable(carrier);
    }
  };

  return (
    <div className="carrier-card">
      <h3>{carrier.Name}</h3>
      <p><strong>ApiKey:</strong> {carrier.ApiKey}</p>
      <p><strong>Enabled:</strong> {isEnabled ? 'Yes' : 'No'}</p>
      <label className="switch">
        <input type="checkbox" checked={isEnabled} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default CarrierCard;