// MainContent.js
import React from 'react';
import DriverCard from './DriverCard';
import CarrierCard from './CarrierCard';
import UserCard from './UserCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import './MainContent.css';

const MainContent = ({
  selectedMenuItem,
  apiKeys,
  selectedApiKey,
  setSelectedApiKey,
  searchQuery,
  handleSearch,
  filteredDrivers,
  handleAddDriver,
  handleEnableDriver,
  handleDisableDriver,
  handleToggleUser,
  successMessages,
  error,
  carrierApiKey,
  setCarrierApiKey,
  handleValidateCarrier,
  carriers,
  handleEnableCarrier,
  handleDisableCarrier,
  users,
  userProfile,
}) => {
  return (
    <div className="main-content">
      {selectedMenuItem === 'Drivers Admin' && (
        <>
          <div className="api-key-container" style={{ marginBottom: '20px' }}>
            <label htmlFor="apiKeySelect">Select Carrier: </label>
            <select
              id="apiKeySelect"
              value={selectedApiKey}
              onChange={(e) => setSelectedApiKey(e.target.value)}
            >
              <option value="">Select an API Key</option>
              {apiKeys.map((apiKey) => (
                <option key={apiKey.ApiKey} value={apiKey.ApiKey}>
                  {apiKey.ApiKey} - {apiKey.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="search-container" style={{ marginBottom: '20px' }}>
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search by username"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="driver-cards-container">
            {filteredDrivers.map((driver) => (
              <DriverCard
                key={driver.HOSDriverId}
                driver={driver}
                onAdd={handleAddDriver}
                onEnable={handleEnableDriver}
                onDisable={handleDisableDriver}
                successMessage={successMessages[driver.HOSDriverId]}
                errorMessage={error}
              />
            ))}
          </div>
        </>
      )}
      {selectedMenuItem === 'Carriers Admin' && (
        <>
          <div className="add-carrier-container">
            <h2>To add a new carrier into Copilot, please follow these steps:</h2>
            <ol>
              <li>Type the provided API key here: 
                <input
                  type="text"
                  id="carrierApiKey"
                  value={carrierApiKey}
                  onChange={(e) => setCarrierApiKey(e.target.value)}
                  style={{ marginLeft: '10px', width: '300px' }} // Set the width here
                />
              </li>
              <li>
                Click here: 
                <button onClick={() =>handleValidateCarrier(carrierApiKey, userProfile)} style={{ marginLeft: '10px', width: '30px', padding: '5px 10px', fontSize: '0.875rem' }}>
                  <FontAwesomeIcon icon={faCheck} style={{ color: 'white' }} />
                </button>
              </li>
              <li>If the API key is valid, it will show the carrier's data in the cards below. If you wish to activate the carrier, click on the enable button.</li>
            </ol>
          </div>
          <div className="carrier-cards-container">
            {carriers.map((carrier) => (
              <CarrierCard
                key={carrier.ApiKey}
                carrier={carrier}
                onEnable={handleEnableCarrier}
                onDisable={handleDisableCarrier}
              />
            ))}
          </div>
        </>
      )}
      {selectedMenuItem === 'Users' && (
        <div className="user-cards-container">
          {users.map((user) => (
            <UserCard
              key={user.name}
              user={user}
              onToggle={handleToggleUser}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MainContent;