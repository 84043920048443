// src/hooks/useDrivers.js
import { useState, useEffect } from 'react';

const useDrivers = (selectedApiKey) => {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDrivers = async () => {
      if (selectedApiKey) {
        try {
          const response = await fetch(`https://fleetbooster.net/getCarrierDriversCopilot?ApiKey=${selectedApiKey}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setDrivers(data);
          setFilteredDrivers(data);
        } catch (error) {
          setError('Error fetching drivers: ' + error.message);
        }
      }
    };

    fetchDrivers();
  }, [selectedApiKey]);

  return { drivers, setDrivers, filteredDrivers, setFilteredDrivers, error };
};

export default useDrivers;