// src/hooks/useApiKeys.js
import { useState, useEffect } from 'react';

const useApiKeys = (userProfile) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [error, setError] = useState('');
 // console.log('in useApiKeus userProfile: ', userProfile);
  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await fetch(`https://fleetbooster.net/getEnabledCarriersCopilot?BusinessPartner=${userProfile.user_metadata.BusinessPartner}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setApiKeys(data);
      } catch (error) {
        setError('Error fetching API keys: ' + error.message);
      }
    };

    fetchApiKeys();
  }, [userProfile]);

  return { apiKeys, error };
};

export default useApiKeys;