// AddUserForm.js
import React, { useState ,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import useAddUser from './hooks/useAddUser';
import './AddUserForm.css';

const AddUserForm = ({ userProfile, businessPartners = [], defaultBusinessPartner }) => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [businessPartner, setBusinessPartner] = useState(defaultBusinessPartner || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { addUser } = useAddUser();
  console.log("Business Partners en AddUserForm",businessPartners);
  useEffect(() => {
    if (defaultBusinessPartner) {
      setBusinessPartner(defaultBusinessPartner);
    }
  }, [defaultBusinessPartner]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    await addUser({ name, lastName, email, businessPartner, password }, userProfile);
    setName('');
    setLastName('');
    setEmail('');
    //setBusinessPartner('');
    setBusinessPartner(defaultBusinessPartner || ''); // Reset to defaultBusinessPartner or empty
    setPassword('');
    setConfirmPassword('');
    setShowPassword(false);
    setEmailError('');
    setPasswordError('');
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form className="add-user-form" onSubmit={handleSubmit}>
      <h2>Add User</h2>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="form-group">
        <label htmlFor="businessPartner">Business Partner Name</label>
        <select
          id="businessPartner"
          value={businessPartner}
          onChange={(e) => setBusinessPartner(e.target.value)}
          required
        >
          <option value="">Select a Business Partner</option>
          {businessPartners.map((bp) => (
            <option key={bp.BusinessPartner} value={bp.BusinessPartner}>
              {bp.BusinessPartner}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <div className="password-group">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="password-toggle-icon"
            onClick={toggleShowPassword}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="confirmPassword">Confirm Password</label>
        <div className="password-group">
          <input
            type={showPassword ? 'text' : 'password'}
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="password-toggle-icon"
            onClick={toggleShowPassword}
          />
        </div>
        {passwordError && <p className="error">{passwordError}</p>}
      </div>
      <button type="submit">Add User</button>
    </form>
  );
};

export default AddUserForm;