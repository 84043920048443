// BusinessPartnerForm.js
import React from 'react';
import './BusinessPartnerForm.css';

const BusinessPartnerForm = ({ bps, onToggleBP }) => {
  //console.log('bps: ', bps);
  return (
    <div className="business-partner-form">
      <h2>My Business Partners</h2>
      <div className="bp-cards-container">
        {bps.map((bp) => (
          <div key={bp.BusinessPartner} className="bp-card">
            <div className="bp-card-header">
              <h3>{bp.BusinessPartner}</h3>
              
            </div>
            <div className="bp-card-body">
              <p><strong>CreatedBy:</strong> {bp.CreatedByBP}</p>
              <p><strong>CreatedByUser</strong> {bp.CreatedByUser}</p>
              <p><strong>Email:</strong> {bp.Email}</p>
              <p><strong>Phone:</strong> {bp.Phone}</p>
              <p><strong>Copilot Enabled:</strong> {bp.CopilotEnabled ? 'Yes' : 'No'}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessPartnerForm;